import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import HeroV8 from "../components/HeroV8";
import FeaturedContent from "../components/FeaturedContent";
import OurProjects from "../components/OurProjects";
import ReactLightboxV2 from "../components/ReactLightboxV2";
import TestimonialSliderDark from "../components/TestimonialSliderDark";
import Seo from "../components/Seo";

import {
  projectFeaturedContent,
  feedback,
  projectsGalleryHeader,
  projectsHeroDetails,
} from "../constants";

const Projects = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImage: allFile(filter: { name: { in: "hero-dhl" } }) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              formats: AUTO
              layout: CONSTRAINED
              placeholder: BLURRED
              aspectRatio: 1.5
            )
          }
        }
      }
      featuredContentImages: allFile(
        filter: {
          name: {
            in: [
              "10-medivet-project"
              "11-medivet-project"
              "12-medivet-project"
            ]
          }
        }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              formats: AUTO
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      galleryImages: allFile(
        filter: {
          name: {
            in: [
              "04-local-installation"
              "02-harvey-nicholes-chiller-upgrade"
              "04-ladbrokes-project"
              "06-ladbrokes-project"
              "07-medivet-project"
              "Longcross-studio_v1"
            ]
          }
        }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              formats: AUTO
              layout: CONSTRAINED
              placeholder: BLURRED
              aspectRatio: 1.1
            )
          }
        }
      }
    }
  `);

  //console.log("Data: ", data);

  const structuredData = (
    <script type="application/ld+json">
      {JSON.stringify([{
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [{
          "@type": "ListItem",
          "position": 1,
          "name": "Medivet Watford",
          "item":  "https://intact-electrical.co.uk/projects/medivet-watford"
        },{
          "@type": "ListItem",
          "position": 2,
          "name": "Biffa workshop",
          "item": "https://intact-electrical.co.uk/projects/biffa-workshop"
        },{
          "@type": "ListItem",
          "position": 3,
          "name": "DHL Reading",
          "item": "https://intact-electrical.co.uk/projects/dhl-reading"
        }]
      },{
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [{
          "@type": "ListItem",
          "position": 1,
          "name": "Ladbrokes Woking",
          "item": "https://intact-electrical.co.uk/projects/ladbrokes-woking"
        },{
          "@type": "ListItem",
          "position": 2,
          "name": "Harvey Nichols",
          "item": "https://intact-electrical.co.uk/projects/harvey-nichols"
        },{
          "@type": "ListItem",
          "position": 3,
          "name": "Domestic project, Household policy - no visible wires",
          "item": "https://intact-electrical.co.uk/projects/domestic-installations"
        }]
      }])}
    </script>
  )

  const featuredContentImages = data.featuredContentImages.nodes;

  const heroBackgroundImage =
    data.heroImage.nodes[0].childImageSharp.gatsbyImageData;

  const {
    buttonLeft,
    buttonLeftLink,
    buttonRight,
    buttonRightLink,
    excerpt,
    titleTop,
    titleBottom,
  } = projectsHeroDetails;

  return (
    <>
      <Seo       
        title="Intact Electrical Projects"
        description="Why our clients trust us, Our client projects, Medivet Watford, Biffa Workshop, DHL Reading, Ladbrokes Woking, Harvey Nichols, Domestic project"
        post={false}
        metaRobots="follow, index"
        structuredData={structuredData}
      />
      <HeroV8
        heroImage={heroBackgroundImage}
        heroTitleTop={titleTop}
        heroTitleBottom={titleBottom}
        excerpt={excerpt}
        btnLeft={buttonLeft}
        btnLeftLink={buttonLeftLink}
        btnRight={buttonRight}
        btnRightLink={buttonRightLink}
      />
      <FeaturedContent
        featuredContent={projectFeaturedContent}
        featuredContentImages={featuredContentImages}
      />
      <OurProjects />
      <ReactLightboxV2
        images={data.galleryImages.nodes}
        galleryContent={projectsGalleryHeader}
        location="services-page"
      />

      <TestimonialSliderDark feedback={feedback} />
    </>
  );
};

export default Projects;
