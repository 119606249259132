import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import styles from "../styles/style.js";
import Headings from "./Headings.js";

const OurProjects = () => {
  const data = useStaticQuery(graphql`
    query {
      smallImageServices: allFile(
        filter: {
          name: {
            in: [
              "05-medivet-project"
              "02-biffa-work-shop"
              "02-harvey-nicholes-chiller-upgrade"
              "10-local-installation"
            ]
          }
        }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              formats: AUTO
              layout: CONSTRAINED
              placeholder: BLURRED
              aspectRatio: 1.0
            )
          }
        }
      }
      largeImageServices: allFile(
        filter: { name: { in: ["dhl-gallery-16", "09-ladbrokes-office"] } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              formats: AUTO
              layout: CONSTRAINED
              placeholder: BLURRED
              aspectRatio: 1.1
            )
          }
        }
      }
    }
  `);

  //console.log("data: ", data);

  const installations = data.smallImageServices.nodes.find((image) => {
    return image.name === "05-medivet-project";
  });

  const eicrPat = data.smallImageServices.nodes.find((image) => {
    return image.name === "02-biffa-work-shop";
  });

  const wiringServices = data.largeImageServices.nodes.find((image) => {
    return image.name === "dhl-gallery-16";
  });

  const lightingSolutions = data.largeImageServices.nodes.find((image) => {
    return image.name === "09-ladbrokes-office";
  });

  const fuseBoard = data.smallImageServices.nodes.find((image) => {
    return image.name === "02-harvey-nicholes-chiller-upgrade";
  });

  const securitySolutuions = data.smallImageServices.nodes.find((image) => {
    return image.name === "10-local-installation";
  });

  return (
    <div className={`bg-[#ffffff] ${styles.paddingX} ${styles.flexCenter}`}>
      <div className={`${styles.boxWidth}`}>
        <section id="our-projects-gallery">
          <Headings
            titleTop="Our client"
            titleBottom="projects"
            description="After project completion we perform rigorous testing, full commissioning and labelling, compile and issue the O&M’s. Intact Electrical are always available to advise our clients on any future work they may require."
          />
          <div className="lg:flex items-stretch mt-4 mb-8">
            <div className="lg:w-1/2">
              <div className="sm:flex items-center justify-between xl:gap-x-8 gap-x-6">
                <div className="sm:w-1/2 relative w-full h-full">
                  <div>
                    <div
                      className="absolute left-0 bottom-0 w-full h-full z-10 "
                      style={{
                        backgroundImage:
                          "linear-gradient(180deg,transparent,rgba(0,0,0,.7))",
                      }}
                    ></div>
                    {/* <p className="p-6 text-xs font-medium leading-3 text-white absolute top-0 right-0 z-10">
                      12 April 2021
                    </p> */}

                    <div className="absolute bottom-0 left-0 p-6 z-10">
                      <h2 className="text-xl text-white">Medivet Watford</h2>
                      <p className="text-base leading-4 text-white mt-2">
                        the ultimate care
                      </p>
                      <div className="focus:outline-none focus:underline flex items-center mt-3 cursor-pointer text-quinary hover:text-white">
                        <p className="pr-2 text-sm font-medium leading-none">
                          <Link
                            to="/projects/medivet-watford"
                            alt="medivet watford project"
                            aria-label="medivet watford project"
                          >
                            Read More
                          </Link>
                        </p>
                        <svg
                          className="fill-stroke"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.75 12.5L10.25 8L5.75 3.5"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <GatsbyImage
                    image={installations.childImageSharp.gatsbyImageData}
                    alt={installations.name}
                    className="w-full"
                    loading="lazy"
                  />
                </div>
                <div className="sm:w-1/2 sm:mt-0 mt-4 relative">
                  <div>
                    <div
                      className="absolute left-0 bottom-0 w-full h-full z-10"
                      style={{
                        backgroundImage:
                          "linear-gradient(180deg,transparent,rgba(0,0,0,.7))",
                      }}
                    ></div>
                    {/* <p className="p-6 text-xs font-medium leading-3 text-white absolute top-0 right-0 z-10">
                      09 August 2023
                    </p> */}
                    <div className="absolute bottom-0 left-0 p-6 z-10">
                      <h2 className="text-xl text-white">Biffa Workshop</h2>
                      <p className="text-base leading-4 text-white mt-2">
                        no time to waste
                      </p>
                      <div className="focus:outline-none focus:underline flex items-center mt-3 cursor-pointer text-quinary hover:text-white">
                        <p className="pr-2 text-sm font-medium leading-none">
                          <Link
                            to="/projects/biffa-workshop"
                            alt="biffa workshop project"
                            aria-label="biffa workshop project"
                          >
                            Read More
                          </Link>
                        </p>
                        <svg
                          className="fill-stroke"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.75 12.5L10.25 8L5.75 3.5"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <GatsbyImage
                    image={eicrPat.childImageSharp.gatsbyImageData}
                    alt={installations.name}
                    className="w-full"
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="relative">
                <div>
                  <div
                    className="absolute left-0 bottom-0 w-full h-full z-10"
                    style={{
                      backgroundImage:
                        "linear-gradient(180deg,transparent,rgba(0,0,0,.7))",
                    }}
                  ></div>
                 {/*  <p className="md:p-10 p-6 text-xs font-medium leading-3 text-white absolute top-0 right-0 z-10">
                    12 April 2021
                  </p> */}
                  <div className="absolute bottom-0 left-0 md:p-10 p-6 z-10">
                    <h2 className="text-xl text-white">DHL Reading</h2>
                    <p className="text-base leading-4 text-white mt-2">
                      simply delivered
                    </p>
                    <div className="focus:outline-none focus:underline flex items-center mt-3 cursor-pointer text-quinary hover:text-white">
                      <p className="pr-2 text-sm font-medium leading-none">
                        <Link
                            to="/projects/dhl-reading"
                            alt="dhl reading project"
                            aria-label="dhl reading project"
                          >
                          Read More
                        </Link>
                      </p>
                      <svg
                        className="fill-stroke"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.75 12.5L10.25 8L5.75 3.5"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <GatsbyImage
                  image={wiringServices.childImageSharp.gatsbyImageData}
                  alt={wiringServices.name}
                  className="w-full mt-8 md:mt-6"
                  loading="lazy"
                  style={{ maxHeight: "540px" }}
                />
                {/* <img
                  className="w-full mt-4 sm:hidden"
                  src="https://i.ibb.co/6XYbN7f/Rectangle-29.png"
                  alt="sitting place"
                /> */}
              </div>
            </div>
            <div className="lg:w-1/2 xl:ml-8 lg:ml-4 lg:mt-0 md:mt-6 mt-4 lg:flex flex-col justify-between">
              <div className="relative">
                <div>
                  <div
                    className="absolute left-0 bottom-0 w-full h-full z-10"
                    style={{
                      backgroundImage:
                        "linear-gradient(180deg,transparent,rgba(0,0,0,.7))",
                    }}
                  ></div>
                  {/* <p className="md:p-10 p-6 text-xs font-medium leading-3 text-white absolute top-0 right-0 z-10">
                    12 April 2021
                  </p> */}
                  <div className="absolute bottom-0 left-0 md:p-10 p-6 z-10">
                    <h2 className="text-xl text-white">Ladbrokes Woking</h2>
                    <p className="text-base leading-4 text-white mt-2">
                      a safe bet
                    </p>
                    <a
                      href="/"
                      className="focus:outline-none focus:underline flex items-center mt-3 cursor-pointer text-quinary hover:text-white"
                    >
                      <p className="pr-2 text-sm font-medium leading-none">
                        <Link
                            to="/projects/ladbrokes-woking"
                            alt="ladbrokes woking project"
                            aria-label="ladbrokes woking project"
                          >
                          Read More
                        </Link>
                      </p>
                      <svg
                        className="fill-stroke"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.75 12.5L10.25 8L5.75 3.5"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
                <GatsbyImage
                  image={lightingSolutions.childImageSharp.gatsbyImageData}
                  alt={lightingSolutions.name}
                  className="w-full"
                  loading="lazy"
                  style={{ maxHeight: "540px" }}
                />
                {/* <img
                  className="w-full sm:hidden"
                  src="https://i.ibb.co/dpXStJk/Rectangle-29.png"
                  alt="sitting place"
                /> */}
              </div>
              <div className="sm:flex items-center justify-between xl:gap-x-8 gap-x-6 md:mt-6 mt-4">
                <div className="relative w-full">
                  <div>
                    <div
                      className="absolute left-0 bottom-0 w-full h-full z-10"
                      style={{
                        backgroundImage:
                          "linear-gradient(180deg,transparent,rgba(0,0,0,.7))",
                      }}
                    ></div>
                    {/* <p className="p-6 text-xs font-medium leading-3 text-white absolute top-0 right-0 z-10">
                      12 April 2021
                    </p> */}
                    <div className="absolute bottom-0 left-0 p-6 z-10">
                      <h2 className="text-xl text-white">Harvey Nichols</h2>
                      <p className="text-base leading-4 text-white mt-2">
                        gourmet service
                      </p>
                      <div className="focus:outline-none focus:underline flex items-center mt-3 cursor-pointer text-quinary hover:text-white">
                        <p className="pr-2 text-sm font-medium leading-none">
                          <Link
                            to="/projects/harvey-nichols"
                            alt="harvey nichols project"
                            aria-label="harvey nichols project"
                          >
                            Read More
                          </Link>
                        </p>
                        <svg
                          className="fill-stroke"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.75 12.5L10.25 8L5.75 3.5"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <GatsbyImage
                    image={fuseBoard.childImageSharp.gatsbyImageData}
                    alt={fuseBoard.name}
                    className="w-full"
                    loading="lazy"
                  />
                </div>
                <div className="relative w-full sm:mt-0 mt-4">
                  <div>
                    <div
                      className="absolute left-0 bottom-0 w-full h-full z-10"
                      style={{
                        backgroundImage:
                          "linear-gradient(180deg,transparent,rgba(0,0,0,.7))",
                      }}
                    ></div>
                    {/* <p className="p-6 text-xs font-medium leading-3 text-white absolute top-0 right-0 z-10">
                      12 April 2021
                    </p> */}
                    <div className="absolute bottom-0 left-0 p-6 z-10">
                      <h2 className="text-xl text-white">
                        Domestic project
                      </h2>
                      <p className="text-base leading-4 text-white mt-2">
                        no visible wires
                      </p>
                      <div className="focus:outline-none focus:underline flex items-center mt-3 cursor-pointer text-quinary hover:text-white">
                        <p className="pr-2 text-sm font-medium leading-none">
                          <Link
                            to="/projects/domestic-installations"
                            alt="The hub farnborough project"
                            aria-label="The hub farnborough project"
                          >
                            Read More
                          </Link>
                        </p>
                        <svg
                          className="fill-stroke"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.75 12.5L10.25 8L5.75 3.5"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <GatsbyImage
                    image={securitySolutuions.childImageSharp.gatsbyImageData}
                    alt={securitySolutuions.name}
                    className="w-full"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default OurProjects;
